/** @jsx jsx */
import React from 'react';
import { jsx, Text } from 'theme-ui';
import { Link } from 'gatsby';
import routes from '../../../routes';
import { HeaderMenuItem } from '../../organisms/Header/types';
import { InfoBannerItem } from '../../organisms/InfoBanner/types';

const { JOIN_OUR_CULTURE, JOIN_THE_DEAL, JOIN_OPPORTUNITIES } = routes;

export const joinSubMenu: HeaderMenuItem[] = [
  {
    label: 'Our culture',
    to: JOIN_OUR_CULTURE,
  },
  {
    label: 'The deal',
    to: JOIN_THE_DEAL,
  },
  {
    label: 'Opportunities',
    to: JOIN_OPPORTUNITIES,
  },
];

export const infoBannerOurCultureItems: InfoBannerItem[] = [
  {
    title: 'Our culture',
    key: '1',
    content: (
      <React.Fragment>
        <Text as="p" variant="h2" mb={['1rem', '1rem', '2.5rem']}>
          We enjoy a brighter culture that helps individuals thrive and move up
          in the world.
        </Text>
        <Link
          to={routes.JOIN_OUR_CULTURE}
          sx={{
            variant: 'links.underlinedLight',
          }}
        >
          See what we offer
        </Link>
      </React.Fragment>
    ),
  },
];
