/* eslint-disable no-underscore-dangle */
import { graphql } from 'gatsby';
import React from 'react';
import { Container } from 'theme-ui';
import RevealAnimationWrapper from '../components/atoms/RevealAnimationWrapper';
import SEO from '../components/atoms/SEO';
import PortableText from '../components/custom/portableText';

import CmsContent from '../components/molecules/CmsContent';
import { CmsContentType } from '../components/molecules/CmsContent/enums';
import InfoBanner from '../components/organisms/InfoBanner';
import { infoBannerOurCultureItems } from '../components/pages/join/consts';
import { JobPageProps } from '../components/pages/join/types';

const JoinOpportunitiesDetails: React.FC<JobPageProps> = ({
  data: { job },
}) => (
  <>
    <SEO prefix={job.name} />
    <Container
      variant="layout.containerNarrow"
      pt={['2.25rem', '2.25rem', '5rem']}
      pb={['10rem', '10rem', '20rem']}
    >
      <RevealAnimationWrapper>
        <CmsContent type={CmsContentType.simple}>
          <time dateTime={job.date}>{job.date}</time>
          <h1>{job.name}</h1>
          <PortableText blocks={job._rawOverview} />
          <PortableText blocks={job._rawSummary} />
          <a href={`mailto:${job.email}`} target="_blank" rel="noreferrer">
            Apply
          </a>
        </CmsContent>
      </RevealAnimationWrapper>
    </Container>
    <InfoBanner items={infoBannerOurCultureItems} />
  </>
);

export default JoinOpportunitiesDetails;

export const pageQuery = graphql`
  query($id: String!) {
    job: sanityJob(id: { eq: $id }) {
      name
      date(formatString: "DD.MM.YY")
      email
      _rawSummary
      _rawOverview
    }
  }
`;
